import util from "lib/util"
export default {
  data() {
    return {
      showLoading: false,
      cancelReason: -1,
      showRule: false,
      reasons: [],
      other: ''
    }
  },
  computed: {
    bussType: function() {
      var order = this.order
      if(order.dispatchId) {
        if(order.tabId == 0) {
          return "PREPAY"
        } else if(order.tabId == 1 || order.tabId == 2){
          return "NOCOURIER"
        } else if(order.tabId == 3) {
          return order.disdoortime < 0 ? "COURIER_TIMEOUT" : "RECEIVE_ORDER"
        } else if(order.istimeout) {
          return "COURIER_TIMEOUT"
        }
      } else {
        return '';
      }
    },
    orderType: function() {
      return this.order.dispatchId ? "DISPATCH" : (this.order.role == "ZNG" ? "SMART_ARK" : "COMMON")
    }
  },
  methods: {
    cancel() {
      var order = this.order
      if(this.cancelReason <= -1) {
        return this.$toast('请选择取消原因')
      }
      if(this.reasons[this.cancelReason].other == '1' && !this.other) {
        return this.$toast('请输入取消原因')
      }
      if(order.dispatchId && order.disdoortime  < 60 && order.disdoortime > 0 && order.filldoortime) {
        return this.$confirm({
          content: "离约定上门时间小于1小时取消，扣除预付费用2元，其他部分退回",
          confirmText: "放弃取消",
          cancelText: "确定取消",
          cancel: () => {
            this.doCancel()
          }
        })
      }
      this.doCancel()
    },
    doCancel: function() {
      var order = this.order
      var reason = this.reasons[this.cancelReason]
      const loading = this.$loading("正在取消...")
      this.$http.post('/apicenter/order.do?method=cancelOrder', {
        data: {
          dispatchid: order.dispatchId || '',
          expid: order.expid,
          sign: order.sign || '',
          cancelmsg: reason.other == '1' ? this.other : reason.text,
          belongto: reason.type,
          batchNo: order.batchNo || ''
        }
      }).then(() => {
        this.$toast("订单已取消")
        loading.hide()
        this.success()
      }).catch(() => {
        this.$toast("订单取消失败，请稍后再试")
        loading.hide()
      })
    },
    toggleRule: function() {
      this.showRule = !this.showRule
    },
    getReason() {
      this.reasons = []
      this.$http.get("/mobclient/apigateway.do?method=cancelOrderReasonList",{
        data: {
          bussType: this.bussType,
          orderType: this.orderType
        }
      }).then(result => {
        var other = result.data.pop()
        this.reasons = result.data.sort(function(){
          return Math.random() - 0.5
        })
        this.reasons.push(other)
      }).catch(() => {
        this.$toast("取消原因列表获取失败，请稍后再试")
      })
    },
    redirect() {
      let lastpage = util.getLastPage()
      if(lastpage) {// 直接刷新页面
        this.$router.go(-1)
      } else {//直接打开的，回到首页
        this.navigator.switch({
          name: "courier"
        })
      }
    },
    success() {
      let event = this.$route.params.event
      event && this.$broadcastChannel.$emit(event)
      if(this.order.batchNo) {
        this.$router.go(-2)
      }
      else {
        this.$router.go(-1)
      }
    }
  },
  created() {
    if(this.$route.params.order) {
      this.order = this.$route.params.order
      this.getReason()
    } else {
      this.redirect()
    }  
  }
}